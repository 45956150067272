footer.site-footer {
    border-bottom: 10px solid #72b08a;
    background: #f3debf url(../img/leafpattern.png) repeat-x;
    background-position: center bottom -14px;

    div.footer-widgets {
        margin:0; 
        padding: 0;

        a {
            @include links; 
        }
    }

    span.gamma.widget-title {
        color: $colorPink; 
        border: 0; 
        margin: 0; 
    }

    div.social-links {
        text-align: center;

        a {
            font-size: 2em; 
        }
        a:hover {
            color: $colorPink; 
        }
    }
} 