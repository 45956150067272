input[type='text'], input[type='number'], input[type='email'], input[type='tel'], input[type='url'], input[type='password'], input[type='search'], textarea, .input-text
 {
    border: 1px solid #ccc;
    padding: 3px 5px;
    min-height: 34px;    
    background-color: #fff;

    &:focus {
        outline: 0; 
    }
}

form.mc4wp-form div.mc4wp-form-fields label, form.mc4wp-form div.mc4wp-form-fields input[type="text"], form.mc4wp-form div.mc4wp-form-fields input[type="email"] {
    width: 100%; 
}