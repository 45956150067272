.site-header div.site-branding {
    display:flex; 
    align-items: center; 

    div img {
        height: 120px; 
        display: inline-block; 
    }

    .site-description{
        padding-left: 50px;
    
        span.page-title {
            display: block; 
            margin:0;
        }
        span.page-slogan {
            font-weight: bold;
            font-size: 1.15em; 
            color: $colorPink;
        }
    }
}

@include screen-min(breakpoint(768px)){
    .woocommerce-active .site-header .site-branding {
        margin-right: 3%; 
    }
}

div.payment-information {    

    margin: 0 2.5em; 
    padding: 0.5em 2.5em;

    @include screen-max(breakpoint(1050px)){
        padding: 0.5em 0;
    }
    
    @include screen-max(breakpoint(768px)){
        display: none; 
    }

    font-size: 0.8em; 
    color: $colorGreen; 

    span + span {
        padding-left: 0.75em; 
    }
}