$fontMain: 'Open Sans', Verdana, Arial, sans-serif;
$fontAccent: 'Merienda One', cursive;
$fontPageTitle: Sacramento, cursive; 
$fontIcons: 'Font Awesome 5 Free';

$fontWeightLight: 100;
$fontWeightNormal: 300;
$fontWeightBold: 600;

body {
	font-family:$fontMain;
	font-size:16px;
	color:$colorBlack;

	@include screen-max(sm){
		font-size:18px;
	}

	@include screen-min(lg){
		font-size:18px;
	}
}

@mixin fontBranding {
	font-family: $fontPageTitle;
	font-size: 4em; 
	font-weight: $fontWeightLight; 
	color: $colorBlack; 
}

@mixin fontTitleCursive{
	font-family:$fontAccent;
	font-size:2.1em;
	font-weight:$fontWeightLight;
	color:$colorBlack;
}

@mixin fontTitleBig{
	font-size:2em;
	font-weight:$fontWeightNormal;
	color:$colorPink;
}

@mixin fontTitleNormal{
	font-size:1.5em;
	font-weight:$fontWeightNormal;
	color:$colorPink;
}

@mixin fontTitleSmall{
	font-size:1em;
	font-weight:$fontWeightBold;
	color:$colorPink;
}

@mixin fontP{
	font-size:1em;
	color:$colorBlack;
}

@mixin links {
	color:$colorPink;
	text-decoration: none; 
	outline: 0; 

	&:active, &:focus {
		outline: 0; 
	}

	&:hover {
		text-decoration: none;
		color: $colorPink; 
	}
}

h1 {
	@include fontTitleCursive;
	margin-top:$spaceMedium;
	margin-bottom:$spaceSmall;
}

div.site-branding div.site-description span.page-title {
	@include fontBranding; 
	margin-top:$spaceMedium;
	margin-bottom:$spaceSmall;
}

h1 .entry-title{
	@include fontTitleCursive;
	margin-top:$spaceMedium;
	margin-bottom:$spaceSmall;
}

h2{
	@include fontTitleBig;
}

h3{
	@include fontTitleNormal;
}

h4{
	@include fontTitleSmall;
}

p{
	@include fontP;
}

a {
	@include links; 

    &.remove-link-style{
        color:inherit;

        &:hover{
            color:inherit;
            text-decoration:none;
        }
    }
}

.signature{
	font-family:$fontAccent;
	font-size:2.5em;
}
