.home-blocks.home-blocks {
    margin-top: 70px; 
    
    .wp-block-column{
        display: flex;
        flex-direction: column; 
        justify-content: space-between; 
        box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
        border-radius: 4px;
        
        p {
            padding: 16px;
        }

        .wp-block-button {
            margin-bottom: 0;
        }
    
        .wp-block-button__link {
            outline: 0;
            width: 100%;
            
            &:hover {
                background-color:$colorPinkLight !important;
            }
        }
    }
}