// Searchpage
.search-page {
    // Display Search Results

    .search-result {
        display: flex;
        flex-wrap: nowrap; 
        align-items: center;

        .shop-meta {
            padding-bottom: 1em; 
        }

        @include screen-max(breakpoint(580px)){
            flex-wrap: wrap; 

            .search-content {
                padding-top: 1em; 
            }

            & > a {
                width: 100%;
                img {
                    width: 100%; 
                }
            }
        }

        @include screen-min(breakpoint(580px)){

            & > a {
                width: 40%; 
            }

            .search-content{
                padding-left: 1em; 
            }
        }
    
        & + .search-result {
            margin-top: 2em; 
            border-top: 1px solid $colorPink;
            padding-top: 2em; 
        }
    }

}

// Searchfield
div.page-content form.searchform button[type="submit"]
{
    margin-left: -5px;
    padding:0;
}


body.woocommerce-active .site-header .site-search {
    width: 18.5%; 
    margin-right: 2em; 

} 

.site-search div.widget_search, div.page-content  {

    form.searchform {
        $inputSize: 34px;

        &:before {
            content: unset;
        }
    
        input {
            border:1px solid $colorBorder;
            padding:3px 5px;
            min-height: $inputSize;
        }
    
        button[type="submit"] {
            clip: unset;
            clip-path: unset;
            -webkit-clip-path: unset;
            min-height: $inputSize;
            border-radius:0;
            outline:0;
            margin: 0;
            border:0;
            background-color:$colorPink;
            color:$colorWhite;
            min-width:$inputSize;
            text-align:center;
            cursor:pointer;
            top: unset;
            left: unset;
        }
    
        .input-button-combinator{
            display:inline-block;
        }
    }
    
}