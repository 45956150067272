
.wc-proceed-to-checkout .button.checkout-button, #payment .place-order .button {
    font-size: 1em; 
}

table.variations td.value {
    margin: 1em 0 0 0;
}

td a.reset_variations {
    margin: 0;
    padding: 0.5em 0; 
}

.soldout {
    padding: 3px 8px;
    text-align: center;
    background: $colorPink;
    color: white;
    font-weight: bold;
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 1em;
}


div.woocommerce-variation-add-to-ca, form.cart {
    button.single_add_to_cart_button {
        background-color: $colorPink;
        outline: 0; 
    }
}

.single-product div.product form.cart .quantity {
    margin: 0;
    
    input.qty {
        padding: 0.55em 1em;
    }
}

// product search 

.widget.woocommerce.widget_product_search form.woocommerce-product-search {
    display: flex; 
    flex-direction: row; 
    position: relative; 
    $inputSize: 34px;

    input[type="text"] {
        flex-basis:80%; 
        padding: 3px 5px; 
    }


    button[type=submit] {
        clip: unset;
        clip-path: unset;
        -webkit-clip-path: unset;
        min-height: $inputSize;
        border-radius:0;
        outline:0;
        border:0;
        background-color:$colorPink;
        color:$colorWhite;
        min-width:$inputSize;
        text-align:center;
        cursor: pointer;
        top: unset;
        left: unset;
        margin: 0;
        position: relative; 

        &.single_add_to_cart_button {
            padding: 0.6em 1.41em; 
        }
    }

    &:before {
        content: ''; 
    }
}

.woocommerce-info {
    background-color: $colorPink; 
}