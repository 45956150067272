.mec-container {
    .mec-event-date.mec-bg-color {
        background-color: $colorPink; 
    }

    .mec-month-divider {
        span {
            font-family: $fontMain; 
            border-bottom: 4px solid $colorLightBrown;
        }
        span:before {
            border-bottom: 4px solid $colorPink;
        }
    }

    a.mec-detail-button{
        background-color: $colorPink; 
        color: $colorWhite; 
    }
}

div.event-content-wrapper {
    display: flex; 

    div.details {
        flex-basis: 70%;
    }

    div.overview-shorts {
        padding: 0 1.5em; 
        flex-basis: 30%; 

        img {
            margin: 1em 0; 
        }

        div.mec-event-meta {
            font-size: 1rem; 
        }

        div.mec-event-sharing div.mec-links-details ul {
            list-style-type: none;
            margin: 0 1.5em;
            padding: 0; 
            display: flex;
            place-content: space-between;
        }
    }

    .mec-export-details ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            margin: 2em 0; 
        }

        li a {
            border-color: $colorPink; 
            color: $colorPink; 
            &:hover {
                background-color: $colorLightBrown;
            }
        }
    }
}


@include screen-max(breakpoint(1020px)){
    div.event-content-wrapper {
        flex-wrap: wrap; 

        
        div.overview-shorts {
            order:1; 
            padding: 0; 
            margin: 2em 0; 
        }
        div.details {
            order: 2; 
        }
    }
}