 .site-header {
    background-color: $colorLightBrown; 

    
    @include screen-min(breakpoint(768px)){
        .storefront-primary-navigation {
            background-color:$colorLighterBrown;
        }
    }

    
    ul.nav-menu {
        li a:hover{
            text-decoration: none; 
        }
        li a:focus {
            outline: 0; 
        }

        li:hover, li.current_page_item, li.page_item:hover {
            background-color: $colorBrown; 

            a {
                color: $colorBlack;
                font-weight: $fontWeightBold;
            }
        }
           
        
        
    }
    .site-header-cart.menu{
        li a.cart-contents {
            &:focus{
                outline: 0; 
            }
            &:hover {
                text-decoration: none; 
            }
        }
        

    }
   
    
} 

button.menu-toggle, button.menu-toggle:hover {
    background-color:$colorLighterBrown;
    border: 0; 
    border-color: transparent; 
}  