.storefront-breadcrumb {
    
    color: $colorLightGreen; 

    a, span {
        color: $colorLightGreen;  
        text-decoration: none;  
        &:hover {
            color: $colorGreen;             
        }
        &:focus {
            outline: 0; 
        }
    }
}